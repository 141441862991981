import React, { useEffect } from "react";
import { Footer } from "../components/Footer";
import { Menu } from "../components/Menu";
import { Header } from "./Header";
import { ListProjects } from "./ListProjects";
import { Testmonial } from "./Testmonial";

export const Home = () => {
  return (
    <>
      <Menu />
      <Header />
      <section className="company-review">
        <div className="custom-container">
          <div className="company-content">
            <br />
            <h2
              data-aos="zoom-out-left"
              data-aos-duration="1500"
              data-w-id="b7284292-8902-a5f4-34fd-2a2b6aa4c4cf"
              style={{ textAlign: "center" }}
            >
              Mais de 100 pequenas e medias empresas usam os nossos produtos
            </h2>
            <div
              data-w-id="b7284292-8902-a5f4-34fd-2a2b6aa4c4d1"
              className="company-list"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                opacity: 1,
              }}
            >
              <div data-aos="fade-left" className="company-item">
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a6f_Frame%20427319455.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="200"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a70_Frame%20427319456.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="400"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a6e_Frame%20427319457.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="600"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a7c_Frame%20427319458.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="800"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a7b_Frame%20427319459.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
            </div>
            <div
              data-w-id="b7284292-8902-a5f4-34fd-2a2b6aa4c4d1"
              className="company-list"
              style={{
                transform:
                  "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
                opacity: 1,
              }}
            >
              <div data-aos="fade-left" className="company-item">
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a6f_Frame%20427319455.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="200"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a70_Frame%20427319456.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="400"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a6e_Frame%20427319457.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="600"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a7c_Frame%20427319458.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="800"
                className="company-item"
              >
                <img
                  src="assets/images/65c235906c10ac1eb5fe5a7b_Frame%20427319459.svg"
                  loading="lazy"
                  alt=""
                  className="compnay-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-section">
        <div className="custom-container">
          <div className="about-content">
            <div className="about-titles">
              <h2
                data-aos="zoom-out-left"
                data-aos-duration="1000"
                data-w-id="d79cc30f-cad2-ae6f-32f0-db58245e1d83"
                className="about-title heading-03 text-linear"
              >
                Sobre Nós
              </h2>
            </div>
            <div className="about-items">
              <div className="about-item-wrap-01">
                <div
                  data-w-id="337dfbc2-5ada-52ed-ec02-5c9f2ec42fef"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="about-item-left dark-shade-bg"
                >
                  <div className="about-text-01 sub-heading-03 white-text">
                    Nós somos
                  </div>
                  <div className="about-text-02 sub-heading-01 text-linear">
                    Uma empresa líder em Angola, especializada em
                    desenvolvimento de software personalizado e integração de
                    sistemas, priorizando soluções inovadoras para o sucesso dos
                    clientes.
                  </div>
                </div>
                <div
                  data-w-id="54a44203-171e-6a18-8d03-535df6d460d6"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="about-item-right"
                >
                  <img
                    data-aos="zoom-out-left"
                    data-aos-duration="1000"
                    src="assets/images/65c235906c10ac1eb5fe5abb_image_5-p-500.webp"
                    loading="lazy"
                    alt=""
                    className="about-img-01"
                  />
                </div>
              </div>
              <div className="about-item-wrap-02">
                <div
                  data-w-id="0a22a17f-0e9d-a5fa-92a0-bd55f519a000"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="about-item dark-shade-bg"
                >
                  <div className="about-text-03 text-linear">+300</div>
                  <div className="about-text-04">Projectos completos</div>
                </div>
                <div
                  data-w-id="cc08c926-4abd-f02f-f906-4eae3f0b9fb0"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="about-item dark-shade-bg"
                >
                  <div className="about-text-03 text-linear">+15</div>
                  <div className="about-text-04">Anos de Experiência</div>
                </div>
                <div
                  id="w-node-a151a5f9-d5ea-ea54-f5dd-e9b5f0996586-b5fe5a11"
                  data-w-id="a151a5f9-d5ea-ea54-f5dd-e9b5f0996586"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    opacity: 1,
                    transformStyle: "preserve-3d",
                  }}
                  className="about-item another dark-shade-bg"
                >
                  <div className="about-text-05">Parceiros</div>
                  <div className="about-partners">
                    <img
                      data-aos="zoom-out-left"
                      src="assets/images/luzianalogo.jpeg"
                      loading="lazy"
                      alt=""
                      className="about-partner"
                      style={{borderRadius:'50%'}}
                    />
                    <img
                      data-aos="zoom-out-left"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                      src="assets/images/NBClogo.jpeg"
                      loading="lazy"
                      alt=""
                      className="about-partner"
                      style={{borderRadius:'50%'}}
                    />
                    <img
                      data-aos="zoom-out-left"
                      data-aos-delay="400"
                      data-aos-duration="1000"
                      src="assets/images/Bepsoftlogo.jpeg"
                      loading="lazy"
                      alt=""
                      className="about-partner"
                      style={{borderRadius:'50%'}}
                    />
                    <img
                      data-aos="zoom-out-left"
                      data-aos-delay="600"
                      data-aos-duration="1000"
                      src="assets/images/Benpsoft.jpeg"
                      loading="lazy"
                      alt=""
                      className="about-partner"
                      style={{borderRadius:'50%'}}
                    />
                    <img
                      data-aos="zoom-out-left"
                      data-aos-delay="800"
                      data-aos-duration="1000"
                      src="assets/images/parceiro.jpeg"
                      loading="lazy"
                      alt=""
                      className="about-partner"
                      style={{borderRadius:'50%'}}
                    />
                  </div>
                  <img
                    src="assets/images/Bensoftlogo.jpeg"
                    loading="lazy"
                    sizes="(max-width: 479px) 92vw, (max-width: 767px) 90vw, (max-width: 1279px) 45vw, 580px"
                    srcSet="assets/images/662b2d37ea4123556e8cbbcf_Rectangle%2023-p-500.png 500w, assets/images/662b2d37ea4123556e8cbbcf_Rectangle%2023.png 580w"
                    alt=""
                    className="gradient-img"
                    style={{borderRadius:'50%'}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ListProjects />
      <section className="services-02">
        <div className="custom-container">
          <div className="project-content">
            <div className="title-wrap-04 _02">
              <h2
                data-w-id="00bfbe68-7cfa-f78b-5064-b566d68b23c3"
                data-aos="flip-down"
                className="title-04 heading-03 text-linear"
              >
                As Nossas Especialidades
              </h2>
            </div>
            <div className="service-list-02">
              <div
                data-w-id="d6c6c852-1e3c-8230-00b6-5a94c7249a50"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="service-item-02 dark-shade-bg"
              >
                <div className="service-count">1</div>
                {/*<img
                  src="assets/images/65c235906c10ac1eb5fe5acf_Lightning.svg"
                  loading="lazy"
                  alt=""
                  className="service-img"
                />*/}
                <div className="service-body">
                  <h3 className="service-title-i sub-heading-02 white-text">
                    Desenvolvimento de software sob medida
                  </h3>
                  <div className="service-text-i body-02---r approx-silver-text">
                    Criamos soluções personalizadas, alinhadas às necessidades
                    únicas de cada cliente, impulsionando a inovação e a
                    eficácia..
                  </div>
                </div>
              </div>
              <div
                data-w-id="353da355-0129-3e96-fdbc-f15cdc370512"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="service-item-02 dark-shade-bg"
              >
                <div className="service-count">2</div>
                {/*<img
                  src="assets/images/65c235906c10ac1eb5fe5ad0_Fire.svg"
                  loading="lazy"
                  alt=""
                  className="service-img"
                />*/}
                <div className="service-body">
                  <h3 className="service-title-i sub-heading-02 white-text">
                    <strong>Consultoria em tecnologia da informação</strong>
                  </h3>
                  <div className="service-text-i body-02---r approx-silver-text">
                    Oferecemos orientação estratégica em TI, auxiliando empresas
                    na tomada de decisões informadas para maximizar o valor
                    tecnológico e impulsionar o crescimento..
                  </div>
                </div>
              </div>
              <div
                data-w-id="7e2f80dd-3416-1888-b35a-2b0d0fae85b1"
                style={{
                  transform:
                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="service-item-02 dark-shade-bg"
              >
                <div className="service-count">3</div>
                {/*<img
                  src="/assets/images/65c235906c10ac1eb5fe5ad1_Shield.svg"
                  loading="lazy"
                  alt=""
                  className="service-img"
                />*/}
                <div className="service-body">
                  <h3 className="service-title-i sub-heading-02 white-text">
                    <strong>Integração de equipes de TI</strong>
                  </h3>
                  <div className="service-text-i body-02---r approx-silver-text">
                    Harmonizamos equipes de TI, fornecendo talento especializado
                    para projetos específicos, promovendo colaboração e
                    eficiência..
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Testmonial />

      <section className="blogs-02">
        <div className="custom-container project01">
          <div className="project-content">
            <div className="title-head">
              <div className="title-wrap-04 _02">
                <h2
                  data-w-id="09e2c2b1-132f-fc64-9957-9f99b42ddf14"
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  className="title-04 heading-03 text-linear"
                >
                  O que mais lhe podemos oferecer?{" "}
                </h2>
              </div>
            </div>
            <div
              data-current="Tab 1"
              data-easing="ease"
              data-duration-in="300"
              data-duration-out="100"
              className="blog-tab-wrap w-tabs"
            >
              <div className="blog-tab-menu hom w-tab-menu" role="tablist">
                <a
                  data-w-tab="Tab 1"
                  className="blog-tab-link w-inline-block w-tab-link w--current"
                  id="w-tabs-0-data-w-tab-0"
                  href="#w-tabs-0-data-w-pane-0"
                  role="tab"
                  aria-controls="w-tabs-0-data-w-pane-0"
                  aria-selected="true"
                >
                  <div>All</div>
                </a>
              </div>
              <div className="blog-tab-content w-tab-content">
                <div
                  data-w-tab="Tab 1"
                  className="tab-pane-blog w-tab-pane w--tab-active"
                  id="w-tabs-0-data-w-pane-0"
                  role="tabpanel"
                  aria-labelledby="w-tabs-0-data-w-tab-0"
                >
                  <div
                    id="w-node-_09e2c2b1-132f-fc64-9957-9f99b42ddf1d-b5fe5a11"
                    className="w-dyn-list"
                  >
                    <div
                      role="list"
                      className="blog-list-01 _01 hom w-dyn-items"
                    >
                      <div
                        data-w-id="09e2c2b1-132f-fc64-9957-9f99b42ddf1f"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        role="listitem"
                        className="w-dyn-item"
                      >
                        <a
                          href="#"
                          className="link-block-c full-width w-inline-block"
                        >
                          <div className="blog-items dark-shade-bg">
                            <div className="blog-thumb">
                              <img
                                loading="lazy"
                                src="/assets/images/galery/hosting.jpeg"
                                alt=""
                                sizes="(max-width: 767px) 85vw, (max-width: 991px) 41vw, (max-width: 1279px) 25vw, 314.6640625px"
                                srcSet="/assets/images/galery/hosting.jpeg"
                                className="blog-img"
                                style={{
                                  transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                  transformStyle: "preserve-3d",
                                }}
                              />
                            </div>
                            <div className="blog-body">
                              <div className="blog-tag">Servidores</div>
                              <div className="blog-text">
                                Hospedagem Dedicado de Websites
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        data-w-id="09e2c2b1-132f-fc64-9957-9f99b42ddf1f"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        role="listitem"
                        className="w-dyn-item"
                      >
                        <a
                          href="#"
                          className="link-block-c full-width w-inline-block"
                        >
                          <div className="blog-items dark-shade-bg">
                            <div className="blog-thumb">
                              <img
                                loading="lazy"
                                src="/assets/images/galery/mail-server.jpeg"
                                alt=""
                                sizes="(max-width: 767px) 85vw, (max-width: 991px) 41vw, (max-width: 1279px) 25vw, 314.6640625px"
                                srcSet="/assets/images/galery/mail-server.jpeg"
                                className="blog-img"
                                style={{
                                  transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                  transformStyle: "preserve-3d",
                                }}
                              />
                            </div>
                            <div className="blog-body">
                              <div className="blog-tag">Servidores</div> 
                              <div className="blog-text">
                                E-mails corporativos
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div
                        data-w-id="09e2c2b1-132f-fc64-9957-9f99b42ddf1f"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        role="listitem"
                        className="w-dyn-item"
                      >
                        <a
                          href="#"
                          className="link-block-c full-width w-inline-block"
                        >
                          <div className="blog-items dark-shade-bg">
                            <div className="blog-thumb">
                              <img
                                loading="lazy"
                                src="/assets/images/galery/e-comerce.jpeg"
                                alt=""
                                sizes="(max-width: 767px) 85vw, (max-width: 991px) 41vw, (max-width: 1279px) 25vw, 314.6640625px"
                                srcSet="/assets/images/galery/e-comerce.jpeg"
                                className="blog-img"
                                style={{
                                  transform:
                                    "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                                  transformStyle: "preserve-3d",
                                }}
                              />
                            </div>
                            <div className="blog-body">
                              <div className="blog-tag">Desenvolvimento</div>
                              <div className="blog-text">
                                Soluções e-comerce
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
