import React from 'react'
import { Footer } from '../components/Footer'
import { Menu } from '../components/Menu'
import { AboutUsHero } from './AboutUsHero'
import { Faqs } from './Faqs'
import { Hiperbite } from './Hiperbite'

import { HowWeDow } from './HowWeDow'
import { Services } from './Services'

export const AboutUs = () => {

    return (
        <>

            <Menu />
            <AboutUsHero />
            <Services />
            <Hiperbite/>
            <HowWeDow />
            <Faqs />
            <Footer />
        </>
    )
}
