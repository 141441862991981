import React from 'react'

export const ListProjects = () => {
    return (
        <>

            <section className="projects-02">
                <div className="custom-container">
                    <div className="project-content">
                        <div className="title-wrap-05">
                            <h2
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-w-id="c33749c2-a766-3b17-7478-451c6d03df03"
                                className="title-04 heading-03 text-linear">A Hiperbite oferece soluções personalizadas de desenvolvimento de software</h2>
                        </div>
                        <div className="project-list-02">
                            <div className="w-dyn-list">
                                <div role="list" className="w-dyn-items">
                                    <div role="listitem" className="w-dyn-item">
                                        <div className="item-wrap">
                                            <a href="#" className="link-block-c _w-100 w-inline-block">
                                                <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df0b" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-item _02 dark-shade-bg">
                                                    <div className="my-div-block">
                                                        <div className="project-bodies-text _02" data-aos="zoom-out-left" data-aos-duration="1000">
                                                            <div className="project-texts">
                                                                <div className="project-text01 sub-heading-04 white-text">Nova erp
                                                                </div>
                                                                <div className="project-text02 body-03 approx-silver-text">Solução abrangente que unifica diversos processos de negócios em um único sistema integrado. Isso inclui desde finanças e contabilidade até gestão de estoque, recursos humanos e cadeia de suprimentos. O objetivo principal é fornecer uma visão holística e em tempo real das operações empresariais, facilitando a gestão e o crescimento da empresa
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="project-icons _02" >
                                                            <img src="assets/images/662b2e1f48b2f2289e79a5f7_Group%201000017041%20(1).svg" loading="lazy" alt="" className="project-icon _02" />
                                                            <img src="assets/images/662b2e1fb239b22dec347651_Group%201171274936%20(1).svg"
                                                                loading="lazy" alt="" className="project-icon _02" /></div>
                                                    </div>
                                                    <img src="assets/images/65c235906c10ac1eb5fe5ab3_Rectangle%2025%20(1).svg" loading="lazy" alt="" className="divider-02" />
                                                    <div className="project-thumb _02">
                                                        <img loading="lazy" src="/assets/images/galery/nova-erp.jpg" alt="" sizes="(max-width: 479px) 83vw, (max-width: 767px) 79vw, (max-width: 991px) 359px, (max-width: 1279px) 28vw, 359px"
                                                            srcSet="/assets/images/galery/nova-erp.jpg"
                                                            className="project-thum" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d" }} />
                                                    </div>
                                                </div>
                                            </a>
                                            <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                                <h3 className="title-project-i heading-04 text-linear" data-aos="fade-down" data-aos-duration="800">Nova ERP</h3>
                                                <div className="project-text-01 body-03 approx-silver-text"
                                                    data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000"
                                                >um sistema de gestão empresarial (ERP) projetado para integrar e automatizar os processos de negócios de uma empresa, melhorando a eficiência, a precisão e a tomada de decisões
                                                </div>
                                                <div className="button-wrap" data-aos="fade-down" data-aos-delay="400" data-aos-duration="800">
                                                    <a href="https://nova.ao" className="link-block-c w-inline-block button">
                                                        <div className="btn-wrap" style={{ opacity: 1 }} >
                                                            <div className="button-01">
                                                                <div className="btn-text white-text">Ver Solução</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-dyn-list">
                                <div role="list" className="w-dyn-items">
                                    <div role="listitem" className="w-dyn-item">
                                        <div className="item-wrap _02">
                                            <a href="#" className="link-block-c ano w-inline-block">
                                                <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df2b" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-item _02 dark-shade-bg another">
                                                    <div className="my-div-block">
                                                        <div className="project-bodies-text _02">
                                                            <div className="project-texts">
                                                                <div className="project-text01 sub-heading-04 white-text">Nzila pro
                                                                </div>
                                                                <div className="project-text02 body-03 approx-silver-text">Plataforma de e-learning projetada para fornecer uma experiência de aprendizado online rica e interativa.
                                                                    É ideal para instituições educacionais, empresas que buscam treinamento corporativo e qualquer organização.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="project-icons _02">
                                                            <img src="assets/images/662b2e1f48b2f2289e79a5f7_Group%201000017041%20(1).svg" loading="lazy" alt="" className="project-icon _02" />
                                                            <img src="assets/images/662b2e1fb239b22dec347651_Group%201171274936%20(1).svg"
                                                                loading="lazy" alt="" className="project-icon _02" />
                                                        </div>
                                                    </div>
                                                    <img src="assets/images/65c235906c10ac1eb5fe5ab3_Rectangle%2025%20(1).svg" loading="lazy" alt="" className="divider-02" />
                                                    <div className="project-thumb _02">
                                                        <img loading="lazy" src="/assets/images/galery/nzila-pro.jpg" alt="" sizes="(max-width: 479px) 83vw, (max-width: 767px) 79vw, (max-width: 991px) 359px, (max-width: 1279px) 28vw, 359px"
                                                            srcSet="/assets/images/galery/nzila-pro.jpg"
                                                            className="project-thum" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d" }} />
                                                    </div>
                                                </div>
                                            </a>
                                            <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df3a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                                <h3 className="title-project-i heading-04 text-linear"
                                                    data-aos="fade-down" data-aos-duration="800"
                                                >Nzila pro</h3>
                                                <div className="project-text-01 body-03 approx-silver-text"
                                                    data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000"
                                                >Plataforma de e-learning abrangente que oferece uma ampla gama de funcionalidades para criar, gerenciar e entregar cursos online de forma eficaz.
                                                </div>
                                                <div className="button-wrap" data-aos="fade-down" data-aos-delay="400" data-aos-duration="800">
                                                    <a href="#" className="link-block-c w-inline-block button">
                                                        <div className="btn-wrap" style={{ opacity: 1 }} >
                                                            <div className="button-01">
                                                                <div className="btn-text white-text">Ver Projecto</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-dyn-list">
                                <div role="list" className="w-dyn-items">
                                    <div role="listitem" className="w-dyn-item">
                                        <div className="item-wrap">
                                            <a href="#" className="link-block-c _w-100 w-inline-block">
                                                <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df4b" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-item _02 dark-shade-bg">
                                                    <div className="my-div-block">
                                                        <div className="project-bodies-text _02">
                                                            <div className="project-texts">
                                                                <div className="project-text01 sub-heading-04 white-text">SMS go
                                                                </div>
                                                                <div className="project-text02 body-03 approx-silver-text">É uma interface que permite a desenvolvedores integrar a funcionalidade de envio e recebimento de mensagens SMS em seus próprios aplicativos, websites ou sistemas. Aqui estão alguns detalhes importantes sobre como funciona uma API Gateway de SMS, suas principais funcionalidades e como usá-la.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="project-icons _02">
                                                            <img src="/assets/images/662b2e1f48b2f2289e79a5f7_Group%201000017041%20(1).svg" loading="lazy" alt="" className="project-icon _02" />
                                                            <img src="/assets/images/662b2e1fb239b22dec347651_Group%201171274936%20(1).svg"
                                                                loading="lazy" alt="" className="project-icon _02" /></div>
                                                    </div>
                                                    <img src="assets/images/65c235906c10ac1eb5fe5ab3_Rectangle%2025%20(1).svg" loading="lazy" alt="" className="divider-02" />
                                                    <div className="project-thumb _02">
                                                        <img loading="lazy" src="/assets/images/galery/sms-go.jpeg" alt="" sizes="(max-width: 479px) 83vw, (max-width: 767px) 79vw, (max-width: 991px) 359px, (max-width: 1279px) 28vw, 359px"
                                                            srcSet="/assets/images/galery/sms-go.jpeg"
                                                            className="project-thum" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d" }} />
                                                    </div>
                                                </div>
                                            </a>
                                            <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df5a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                                <h3 className="title-project-i heading-04 text-linear"
                                                    data-aos="fade-down" data-aos-duration="800"
                                                >SMS go</h3>
                                                <div className="project-text-01 body-03 approx-silver-text"
                                                    data-aos="fade-down" data-aos-delay="200" data-aos-duration="1000"
                                                >Integrar uma API Gateway de SMS pode simplificar enormemente a comunicação com clientes e usuários, proporcionando uma maneira eficiente e escalável de enviar e receber mensagens de texto.
                                                </div>
                                                <div className="button-wrap">
                                                    <a href="#" className="link-block-c w-inline-block button" data-aos="fade-down" data-aos-delay="400" data-aos-duration="800">
                                                        <div className="btn-wrap" style={{ opacity: 1 }} >
                                                            <div className="button-01">
                                                                <div className="btn-text white-text">Ver projecto</div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
