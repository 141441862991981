import React from 'react'

export const Menu = () => {
    return (

        <div

            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom"
            style={{ position: 'fixed', width: '100%', zIndex: 9999 }}>

            <section className="navigation">
                <div data-animation="default" data-collapse="tiny" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
                    <div className="nav-container w-container">
                        <a href="#" className="link-block-c brandhide w-inline-block"><img src="assets/images/logo/ico_orange.svg" loading="lazy" alt="" className="brand" style={{width:20}}/>
                        <span style={{color:'#f15c12', padding: '0px 15px', fontSize:16}}>Hiperbite</span>
                        </a>
                        <nav role="navigation" id="w-node-_6c84402e-aaaa-39b4-6cd5-c93d74528d05-74528d00" className="nav-menu-light w-nav-menu" style={{ transform: "translateY(0px) translateX(0px)" }}>

                            <a href="/" aria-current="page" className="link-block-c n-full middlen w-inline-block w--current">
                                <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                    <div className="nav-link---logo body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                        <img
                                            src="/assets/images/logo/ico_white.svg" loading="lazy" alt="" className="brand" />

                                    </div>
                                    <nav className="home-drop-list white-bg blog w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2"></nav>
                                </div>
                            </a>
                            <a href="/" aria-current="page" className="link-block-c n-full w-inline-block w--current">
                                <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                    <div className="nav-link body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                        <div>Início</div>
                                    </div>
                                    <nav className="home-drop-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0"></nav>
                                </div>
                            </a>
                            <a href="/about-us" className="link-block-c n-full w-inline-block">
                                <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                    <div className="nav-link body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                        <div>Sobre Nós</div>
                                    </div>
                                    <nav className="home-drop-list white-bg pricing w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1"></nav>
                                </div>
                            </a>
                            <a href="/projects" className="link-block-c n-full w-inline-block">
                                <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                    <div className="nav-link body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                        <div>Projectos</div>
                                    </div>
                                    <nav className="home-drop-list white-bg contact w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3"></nav>
                                </div>
                            </a>
                            {/*<a href="/blogs" className="link-block-c n-full w-inline-block">
                            <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                <div className="nav-link body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                    <div>Blog</div>
                                </div>
                                <nav className="home-drop-list white-bg pages w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4"></nav>
                            </div>
                        </a>*/}
                            <a href="/contacts" className="link-block-c n-full w-inline-block">
                                <div data-hover="true" data-delay="0" className="move-down-02 w-dropdown" style={{ maxWidth: 605 }}>
                                    <div className="nav-link body-02---r white-text w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4" aria-haspopup="menu" aria-expanded="false" role="button" tabIndex={0}>
                                        <div>Contactos</div>
                                    </div>
                                    <nav className="home-drop-list white-bg pages w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4"></nav>
                                </div>
                            </a>
                        </nav>
                        <div id="w-node-_6c84402e-aaaa-39b4-6cd5-c93d74528d1e-74528d00" className="menu-button w-nav-button" style={{ WebkitUserSelect: "text" }} aria-label="menu" role="button" tabIndex={0} aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
                            <div className="icon w-icon-nav-menu"></div>
                        </div>
                    </div>
                    <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{ display: "none" }}></div>
                </div>
            </section>


        </div>
    )
}