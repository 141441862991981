import React from 'react'
import './Done.scss';
export const Done = () => {
    return (
        <div id="Done">


            <main>
                <section id="snapping" className="section">
                    <div className=" content w-dyn-list">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <div className="item-wrap">
                                    <div className="text link-block-c _w-80 w-inline-block">
                                        <img src={require('../../assets/images/ra2.png')} alt="" />
                                    </div>
                                    <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                        <h2
                                            style={{
                                                fontSize: 48, lineHeight: 1
                                            }}
                                            data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom"
                                            data-w-id="c33749c2-a766-3b17-7478-451c6d03df03"
                                            className="title-04 heading-03 text-linear">A Hiperbite oferece soluções personalizadas de desenvolvimento de software</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="scrolling" className="section">
                    <div className="content">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <div className="item-wrap">
                                    <div className="text link-block-c _w-100 w-inline-block">
                                        <img src={require('../../assets/images/ra1.png')} alt="" />
                                    </div>
                                    <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ maxWidth: 350, transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                        <h2
                                            style={{
                                                fontSize: 48, lineHeight: 1
                                            }}
                                            data-aos="fade-up"
                                            data-aos-anchor-placement="top-bottom"
                                            data-w-id="c33749c2-a766-3b17-7478-451c6d03df03"
                                            className="title-04 heading-03 text-linear">Com uma paixão pela criatividade e um compromisso com a excelência, capacitamos as marcas para prosperarem no cenário digital em constante evolução..</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*
                <section id="layout" className="section">
                    <div className="content">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <div className="item-wrap">
                                    <div className="text link-block-c _w-100 w-inline-block">
                                    <img src={require('../../assets/images/ra2.png')} alt="" />
                                        <p>We set the scrolling element, in this case our <code className="selector">HTML</code> element, to forcibly snap to the Y axis by using <code className="property">scroll-snap-type: y mandatory</code>.</p>

                                        <p>And then we set <code className="selector">section</code> as the snapping elements by using <code className="property">scroll-snap-align: start</code>.</p>
                                    </div>
                                    <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                        <h2><strong>First</strong>, we set up the <em>snapping</em> points</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="transition" className="section">
                    <div className="content">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <div className="item-wrap">
                                    <div className="text link-block-c _w-100 w-inline-block">
                                        <img src="https://assets.codepen.io/197359/flower-white.png" alt="" />
                                        <p>We set the scrolling element, in this case our <code className="selector">HTML</code> element, to forcibly snap to the Y axis by using <code className="property">scroll-snap-type: y mandatory</code>.</p>

                                        <p>And then we set <code className="selector">section</code> as the snapping elements by using <code className="property">scroll-snap-align: start</code>.</p>
                                    </div>
                                    <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                        <h2><strong>First</strong>, we set up the <em>snapping</em> points</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="caveats" className="section">
                    <div className="content">
                        <div role="list" className="w-dyn-items">
                            <div role="listitem" className="w-dyn-item">
                                <div className="item-wrap">
                                    <div className="text link-block-c _w-100 w-inline-block">
                                        <img src="https://assets.codepen.io/197359/flower-white.png" alt="" />
                                        <p>We set the scrolling element, in this case our <code className="selector">HTML</code> element, to forcibly snap to the Y axis by using <code className="property">scroll-snap-type: y mandatory</code>.</p>

                                        <p>And then we set <code className="selector">section</code> as the snapping elements by using <code className="property">scroll-snap-align: start</code>.</p>
                                    </div>
                                    <div data-w-id="c33749c2-a766-3b17-7478-451c6d03df1a" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="project-titles-wrap">
                                        <h2><strong>First</strong>, we set up the <em>snapping</em> points</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
 */}
            </main>

        </div>
    )
}
