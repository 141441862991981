import React from 'react'
import './Particles.scss'

export const Particles = () => {
    let objs = []

    for (let i = 0; i < 100; i++) {
        objs.push(() => <div className="circle-container">
            <div className="circle"></div>
        </div>)
    }

    return (
        <div className='container'>
            {objs.map((I: any) => <>
                <I />
            </>)}
        </div>
    )
}
export const Particlet = () => {
    let objs = []

    for (let i = 0; i < 100; i++) {
        objs.push(() => <div className="circle-container">
            <div className="circle"></div>
        </div>)
    }

    return (
        <>
            <div className="mod">
                <div className="cube">

                    <div className="faces f1">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                    <div className="faces f2">

                        <div className="dot p10"></div>
                        <div className="dot p11"></div>
                        <div className="dot p12"></div>
                        <div className="dot p13"></div>

                    </div>

                    <div className="faces f3">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                    <div className="faces f4">

                        <div className="dot p10"></div>
                        <div className="dot p11"></div>
                        <div className="dot p12"></div>
                        <div className="dot p13"></div>

                    </div>

                    <div className="faces f5">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                    <div className="faces f6">

                        <div className="dot p10"></div>
                        <div className="dot p11"></div>
                        <div className="dot p12"></div>
                        <div className="dot p13"></div>

                    </div>

                    <div className="faces f7">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                    <div className="faces f8">

                        <div className="dot p10"></div>
                        <div className="dot p11"></div>
                        <div className="dot p12"></div>
                        <div className="dot p13"></div>

                    </div>

                    <div className="faces f9">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                    <div className="faces f10">

                        <div className="dot p10"></div>
                        <div className="dot p11"></div>
                        <div className="dot p12"></div>
                        <div className="dot p13"></div>

                    </div>

                    <div className="faces f11">

                        <div className="dot p1"></div>
                        <div className="dot p2"></div>
                        <div className="dot p3"></div>

                        <div className="dot p4"></div>
                        <div className="dot p5"></div>
                        <div className="dot p6"></div>

                        <div className="dot p7"></div>
                        <div className="dot p8"></div>
                        <div className="dot p9"></div>

                    </div>

                </div>

                <div className="project-name">
                    <div className="l1">project-</div>
                    <div className="l2">-quantum</div>
                </div>

            </div>
        </>
    )
}
