import React from 'react'
import { Menu } from '../components/Menu'

export const NotFound = () => {
  return (
    <>

      <Menu />
      <section className="projects-01">
        <div className="custom-container project01">

          <div className="title-wraps-01">

            <h2 data-w-id="a34abe5e-5450-365a-8b44-1d5ef3acbb5b"
              className="hero-titles-01 heading-02 text-linear">
              <br />:(</h2><br />


            <h4 data-w-id="a34abe5e-5450-365a-8b44-1d5ef3acbb5b"
              className="hero-titles-01 heading-02 text-linear">Oops! Parece que você encontrou um buraco negro na nossa galáxia</h4>



            <div data-w-id="354793f9-f101-5fb9-f2ff-af41cff2bfe8"
              className="hero-texts-01 body-01---r approx-silver-text">404: Opa! Essa página se perdeu no espaço.</div>
          </div>
          <div className="project-content">
            <div data-delay="4000" data-animation="slide" className="project-sliders w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
              <a data-w-id="8abe1496-eb78-a713-b60f-75b762d700a9" href="/" className="link-block-c w-inline-block" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", transformStyle: "preserve-3d", opacity: 1 }}>
                <div className="btn-wrap" style={{ opacity: 1 }}>
                  <div className="button-01">
                    <div className="btn-text white-text">Ir ao início</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
