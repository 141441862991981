import React from 'react'
import { Particles } from './Particles'


export const Testmonial = () => {

    return (
        <>

            <section className="testmonial">
                <div className="custom-container project01">
                    <div className="project-content">
                        <div className="title-wrap-04">
                            <h2 className="title-04 heading-02 text-linear"
                                data-aos="fade-right"
                                data-aos-duration="1000"
                            >Encontre comentários de <br />Nossos clientes</h2>
                        </div>
                        <div data-delay="4000" data-animation="slide" className="project-sliders w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true"
                            role="region" aria-label="carousel">
                            <div className="project-mask w-slider-mask" id="w-slider-mask-0">
                                <div className="project-slide w-slide" aria-label="1 of 4" role="group" style={{ transform: "translateX(0px)", opacity: 1 }}>
                                    <div className="test-wrapper"><img src="assets/images/65c235906c10ac1eb5fe5ae8_Rectangle%2040.svg" loading="lazy" alt="" className="left-img" />
                                        <div data-w-id="370dc586-c14e-5bab-6944-a5879c7c149c" style={{ transform: "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", opacity: 1, transformStyle: "preserve-3d" }} className="testmnial-wrap dark-shade-bg">
                                            <div className="testmnial-left">
                                                <img src="assets/images/65c235906c10ac1eb5fe5ae4_group_1000014391-p-500.webp" loading="lazy" sizes="(max-width: 479px) 83vw, 332px" srcSet="assets/images/65c235906c10ac1eb5fe5ae4_group_1000014391-p-500.webp 500w, assets/images/65c235906c10ac1eb5fe5ae4_group_1000014391-p-500.webp"
                                                    alt="" className="image-4" />

                                            </div>
                                            <div className="testmnial-right">

                                                <h1 style={{ fontSize: 300, fontFamily: "'Times New Roman', Times" }} className="rose-bonbon-text">
                                                    "
                                                </h1>
                                                <div className="text-block-4 sub-heading-03 approx-silver-text">“Trabalhar com a Hiperbite foi uma experiência excepcional. Sua equipe altamente qualificada e dedicada nos ajudou a desenvolver uma solução de software personalizada que atendeu perfeitamente às nossas necessidades.
                                                </div>
                                                <p className="paragraphs4 body-01---r">Dicla Dinis<span className="text-span"> Co-Founder</span></p>
                                            </div>
                                        </div>
                                        <img src="assets/images/65c235906c10ac1eb5fe5ae7_Rectangle%2039.svg" loading="lazy" alt="" className="right-img" />
                                    </div>
                                </div>
                                <div className="project-slide w-slide" aria-label="2 of 4" role="group" aria-hidden="true" style={{ transform: "translateX(0px)", opacity: 1 }}>
                                    <div className="test-wrapper" aria-hidden="true">
                                        <img src="assets/images/65c235906c10ac1eb5fe5ae8_Rectangle%2040.svg" loading="lazy" alt="" className="left-img" aria-hidden="true" />
                                        <div className="testmnial-wrap dark-shade-bg" aria-hidden="true">
                                            <div className="testmnial-left" aria-hidden="true">
                                                <img src="/assets/images/persons/person-2.png" loading="lazy" sizes="(max-width: 479px) 83vw, 332px" srcSet="/assets/images/persons/person-2.png"
                                                    alt="" className="image-4" aria-hidden="true" />
                                            </div>
                                            <div className="testmnial-right" aria-hidden="true">

                                                <h1 style={{ fontSize: 300, fontFamily: "'Times New Roman', Times" }} className="rose-bonbon-text">
                                                    "
                                                </h1>
                                                <div className="text-block-4 sub-heading-03 approx-silver-text" aria-hidden="true">
                                                    “Desde que começamos a usar o Nova ERP da Hiperbite, nossa produtividade disparou! A integração de todas as operações em um único sistema nos permitiu economizar tempo e minimizar erros. Definitivamente, uma escolha inteligente para otimizar nossos processos</div>
                                                <p className="paragraphs4 body-01---r" aria-hidden="true">Daniel Vieira <span className="text-span" aria-hidden="true">Co-Founder</span></p>
                                            </div>
                                        </div>
                                        <img src="assets/images/65c235906c10ac1eb5fe5ae7_Rectangle%2039.svg" loading="lazy" alt="" className="right-img" aria-hidden="true" />
                                    </div>
                                </div>
                                <div className="w-slide" aria-label="3 of 4" role="group" aria-hidden="true" style={{ transform: "translateX(0px)", opacity: 1 }}>
                                    <div className="test-wrapper" aria-hidden="true">
                                        <img src="assets/images/65c235906c10ac1eb5fe5ae8_Rectangle%2040.svg" loading="lazy" alt="" className="left-img" aria-hidden="true" />
                                        <div className="testmnial-wrap dark-shade-bg" aria-hidden="true">
                                            <div className="testmnial-left" aria-hidden="true">
                                                <img src="/assets/images/persons/person-1.png" loading="lazy" sizes="(max-width: 479px) 83vw, 332px" srcSet="/assets/images/persons/person-1.png"
                                                    alt="" className="image-4" aria-hidden="true" />
                                            </div>
                                            <div className="testmnial-right" aria-hidden="true">

                                                <h1 style={{ fontSize: 300, fontFamily: "'Times New Roman', Times" }} className="rose-bonbon-text">
                                                    "
                                                </h1>
                                                <div className="text-block-4 sub-heading-03 approx-silver-text" aria-hidden="true">
                                                    “Valorizo ​​muito a nossa colaboração. Agradeço a antiguidade dos caras que trabalham sem a necessidade de explicações explícitas de detalhes e sua disposição em trazer</div>
                                                <p className="paragraphs4 body-01---r" aria-hidden="true">David Henrique <span className="text-span" aria-hidden="true">Co-Founder</span></p>
                                            </div>
                                        </div>
                                        <img src="assets/images/65c235906c10ac1eb5fe5ae7_Rectangle%2039.svg" loading="lazy" alt="" className="right-img" aria-hidden="true" />
                                    </div>
                                </div>

                                <div aria-live="off" aria-atomic="true" className="w-slider-aria-label" data-wf-ignore=""></div>
                            </div>
                            <div className="arrow left w-slider-arrow-left" role="button" tabIndex={0} aria-controls="w-slider-mask-0" aria-label="previous slide">
                                <img loading="lazy" src="assets/images/65c235906c10ac1eb5fe5aae_Group%2025.svg" alt="" className="arrow-icon" />
                            </div>
                            <div className="arrow right w-slider-arrow-right" role="button" tabIndex={0} aria-controls="w-slider-mask-0" aria-label="next slide" >
                                <img loading="lazy" src="assets/images/65c235906c10ac1eb5fe5aa7_Group%2024.svg" alt="" className="arrow-icon" /></div>
                            <div className="slide-nav w-slider-nav w-round w-num">
                                <div className="w-slider-dot w-active" data-wf-ignore="" aria-label="Show slide 1 of 4" aria-pressed="true" role="button" tabIndex={0} style={{ marginLeft: 3, marginRight: 3 }}>
                                    1</div>
                                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 2 of 4" aria-pressed="false" role="button" tabIndex={-1} style={{ marginLeft: 3, marginRight: 3 }}>2</div>
                                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 3 of 4" aria-pressed="false" role="button" tabIndex={-1} style={{ marginLeft: 3, marginRight: 3 }}>3</div>
                                <div className="w-slider-dot" data-wf-ignore="" aria-label="Show slide 4 of 4" aria-pressed="false" role="button" tabIndex={-1} style={{ marginLeft: 3, marginRight: 3 }}>4</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Particles />
        </>
    )
}
