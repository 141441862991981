import React from 'react'
import './Hiperbite.scss';
export const Hiperbite = () => {
    return (
        <div id="Hiperbite">

            <section className="section top-section">
                <div className="content-container content-theme-dark">
                    <div className="content-inner">
                        <div className="content-center">
                            <h2
                                data-aos="fade-down"
                                data-aos-duration="500"
                                data-w-id="0dd1dd4d-8653-65b4-25a2-40904f85a6d8"
                                className="hero-title-02 heading-01 text-linear-bonbon "
                            >
                                Hiperbite
                            </h2>
                            <p>Solução tecnológica sob medida</p>
                            <h4>Na Hiperbite, entendemos que cada negócio possui suas próprias necessidades e desafios únicos. É por isso que oferecemos soluções tecnológicas sob medida, projetadas para atender às suas especificações e impulsionar seu sucesso.</h4>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section middle-section">
                <div className="content-container content-theme-light">
                    <div className="content-inner">
                        <div className="content-center">

                            <h2
                                data-aos="fade-down"
                                data-aos-duration="500"
                                data-w-id="0dd1dd4d-8653-65b4-25a2-40904f85a6d8"
                                className="hero-title-02 heading-01 text-linear-bonbon "
                            >
                                Hiperbite
                            </h2>
                            <p>Consultoria em Tecnologia da Informação</p>
                            <h4>Na Hiperbite, oferecemos serviços de consultoria em Tecnologia da Informação (TI) para ajudar sua empresa a alcançar seus objetivos estratégicos e operacionais por meio do uso eficaz da tecnologia. Nossa abordagem personalizada e colaborativa garante que você obtenha o máximo valor dos investimentos em TI.</h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section bottom-section">
                <div className="content-container content-theme-light">
                    <div className="content-inner">
                        <div className="content-center">
                            <h2
                                data-aos="fade-down"
                                data-aos-duration="500"
                                data-w-id="0dd1dd4d-8653-65b4-25a2-40904f85a6d8"
                                className="hero-title-02 heading-01 text-linear-bonbon "
                            >
                                Hiperbite
                            </h2>
                            <p>Soluções de Software Personalizadas que Impulsionam o Seu Negócio</p>
                            <h4>entendemos que cada empresa tem necessidades únicas. Por isso, oferecemos soluções de software personalizadas que se adaptam perfeitamente ao seu negócio, ajudando a melhorar a eficiência, a produtividade e a competitividade.</h4>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
