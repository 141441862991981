import React from 'react'

export const Faqs = () => {
    return (
        <>
            <section className="faqs">
                <div className="custom-container">
                    <div className="faqs-content">
                        <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2dec" className="title-wrap-03">
                            <h2

                                data-aos="fade-down"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-duration="8000"
                                className="title-02 heading-03 text-linear">Perguntas frequentes</h2>
                        </div>
                        <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2def" className="faq-body-wrap dark-shade-bg">
                            <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2df0" className="faq-thumbs--p1" style={{ padding: 50 }}>
                                <img src="/assets/images/logo/logo_orange.svg"
                                    loading="lazy"

                                    srcSet="/assets/images/logo/logo_orange.svg"
                                    alt="Thumb img faq" className="faq-thumb-img"
                                    style={{ zoom: .2, opacity: 0.3 }}
                                /></div>
                            <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2df2" className="faq-body">
                                <div className="faq-question-answer first">
                                    <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2df4" className="faq-question">
                                        <h3 className="question-title sub-heading-04 white-text">What services does Spacefor offer?</h3><img src="assets/images/65c235906c10ac1eb5fe5aa6_Group%20427319372%20(1).svg" loading="lazy" alt="Plus Icon" className="down-caret-icon fist" />
                                    </div>
                                    <p className="faq-answer body-02---r approx-silver-text first">Spacefor offers a comprehensive range of services tailored to meet the diverse needs of our clients. Our offerings include UI/UX design, prototype development, and accessibility compliance.</p>
                                </div>
                                <div className="faq-question-answer">
                                    <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2dfb" className="faq-question">
                                        <h3 className="question-title sub-heading-04 white-text">How does Spacefor approach projects?</h3><img src="assets/images/65c235906c10ac1eb5fe5aa6_Group%20427319372%20(1).svg" loading="lazy" alt="Plus Icon" className="down-caret-icon" />
                                    </div>
                                    <p className="faq-answer body-02---r approx-silver-text">Webflow is more than just a visual way to code — it&#x27;s also a built in publishing and hosting platform. This means you can go from zero to published.
                                    </p>
                                </div>
                                <div className="faq-question-answer">
                                    <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2e02" className="faq-question">
                                        <h3 className="question-title sub-heading-04 white-text">What industries does Spacefor work with?</h3><img src="assets/images/65c235906c10ac1eb5fe5aa6_Group%20427319372%20(1).svg" loading="lazy" alt="Plus Icon" className="down-caret-icon" />
                                    </div>
                                    <p className="faq-answer body-02---r approx-silver-text">Webflow is more than just a visual way to code — it&#x27;s also a built in publishing and hosting platform. This means you can go from zero to published.
                                    </p>
                                </div>
                                <div className="faq-question-answer">
                                    <div data-w-id="699e25d9-2085-241a-d30a-0e8ab35c2e09" className="faq-question">
                                        <h3 className="question-title sub-heading-04 white-text">How can I get started with Spacefor?</h3><img src="assets/images/65c235906c10ac1eb5fe5aa6_Group%20427319372%20(1).svg" loading="lazy" alt="Plus Icon" className="down-caret-icon" />
                                    </div>
                                    <p className="faq-answer body-02---r approx-silver-text">Webflow is more than just a visual way to code — it&#x27;s also a built in publishing and hosting platform. This means you can go from zero to published.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
