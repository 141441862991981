import React from 'react'

export const HowWeDow = () => {
    return (
        <>

            <section className="content-section">
                <div className="custom-container service">
                    <div className="contents-wrap">
                        <div className="title-wrap-03">
                            <h2 data-w-id="b9e8e90d-4a75-1244-1e93-f52d15bf1fd9"

                                data-aos="fade-zoom-in"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                className="title-02 heading-03 text-linear">Primeiro passo para o sucesso digital</h2>
                        </div>
                        <div className="content-list">
                            <div className="content-item dark-shade-bg">
                                <h2

                                    data-aos="fade-zoom-in"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"
                                    data-w-id="b9e8e90d-4a75-1244-1e93-f52d15bf1fdf" className="item-title heading-04 text-linear"><strong>PLANEAMENTO</strong></h2>
                                <div className="content-divider"></div>
                                <div className="content-text-wrap"><img src="assets/images/65c235906c10ac1eb5fe5a88_Group%201000015333.svg" loading="lazy" data-w-id="7b997476-329f-8b85-357b-d53228462ba6" alt="" className="bullete" />
                                    <div data-w-id="3848f590-15ea-80f0-9d44-885a043f0b6d" className="content-texts">
                                        <h3 className="item-title-01 sub-heading-01 approx-silver-text">O planejamento é a base de todo projeto bem-sucedido</h3>
                                        <div className="item-text-01 body-02---r approx-silver-text">Em nossa empresa, dedicamos tempo e recursos para entender profundamente as necessidades e expectativas de nossos clientes.</div>
                                        <ul role="list" className="item-lists">
                                            <li className="item-item body-02---r approx-silver-text">Análise de Requisitos.</li>
                                            <li className="item-item body-02---r approx-silver-text">Definição de Objetivos.</li>
                                            <li className="item-item body-02---r approx-silver-text">Alocação de Recursos.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="content-item dark-shade-bg">
                                <h2

                                    data-aos="fade-zoom-in"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"

                                    data-w-id="022759ca-c4e3-3268-3317-0d9eee3cc7bb" className="item-title heading-04 text-linear"><strong>EXECUÇÃO</strong></h2>
                                <div className="content-divider"></div>
                                <div className="content-text-wrap"><img src="assets/images/65c235906c10ac1eb5fe5a86_Group%201000015333%20(1).svg" loading="lazy" data-w-id="022759ca-c4e3-3268-3317-0d9eee3cc7bf" alt="" className="bullete" />
                                    <div data-w-id="022759ca-c4e3-3268-3317-0d9eee3cc7c0" className="content-texts">
                                        <h3 className="item-title-01 sub-heading-01 approx-silver-text">A execução é onde as ideias e os planos ganham vida</h3>
                                        <div className="item-text-01 body-02---r approx-silver-text">Nosso time de profissionais altamente qualificados e experientes trabalha de forma colaborativa e focada, aplicando as melhores práticas e tecnologias disponíveis.</div>
                                        <ul role="list" className="item-lists">
                                            <li className="item-item body-02---r approx-silver-text">Desenvolvimento Ágil.</li>
                                            <li className="item-item body-02---r approx-silver-text">Monitoramento Contínuo.</li>
                                            <li className="item-item body-02---r approx-silver-text">Qualidade e Testes.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="content-item dark-shade-bg">
                                <h2

                                    data-aos="fade-zoom-in"
                                    data-aos-delay="100"
                                    data-aos-duration="1000"
                                    data-w-id="fbc191d3-3fa8-7680-e8a8-9344c6096463" className="item-title heading-04 text-linear"><strong>ENTREGA A TEMPO</strong></h2>
                                <div className="content-divider"></div>
                                <div className="content-text-wrap"><img src="assets/images/65c235906c10ac1eb5fe5a87_Group%201000015333%20(2).svg" loading="lazy" data-w-id="fbc191d3-3fa8-7680-e8a8-9344c6096467" alt="" className="bullete" />
                                    <div data-w-id="fbc191d3-3fa8-7680-e8a8-9344c6096468" className="content-texts">
                                        <h3

                                            data-aos="fade-up"
                                            data-aos-delay="100"
                                            data-aos-duration="1000"
                                            className="item-title-01 sub-heading-01 approx-silver-text">Cumprir prazos é uma prioridade para nós.</h3>
                                        <div className="item-text-01 body-02---r approx-silver-text">Entendemos que a entrega pontual é crucial para o sucesso dos nossos clientes e, por isso, estabelecemos processos rigorosos de controle e monitoramento.</div>
                                        <ul role="list" className="item-lists">
                                            <li className="item-item body-02---r approx-silver-text">Definição de Cronogramas.</li>
                                            <li className="item-item body-02---r approx-silver-text">Gestão de Tarefas.</li>
                                            <li className="item-item body-02---r approx-silver-text">Feedback Contínuo.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
