import React from 'react'
import { Faqs } from '../AboutUs/Faqs'
import { Footer } from '../components/Footer'
import { Menu } from '../components/Menu'

import { Header } from './Header'
import MapsLocation from './MapsLocation'


export const Contacts = () => {
    return (
        <>

            <Menu />
            <Header />
            <MapsLocation />
            <Faqs />
            <Footer />
        </>
    )
}
