import React from 'react'

import { Map, Marker } from "pigeon-maps"

function MapsLocation() {
    return (
        <>
            <section className="content-section">
                <div className="custom-container service">
                    <div className="contents-wrap">
                        <div className="title-wrap-03">
                            <h2
                                data-aos="fade-down"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-duration="8000"
                                data-w-id="b9e8e90d-4a75-1244-1e93-f52d15bf1fd9"

                                className="title-02 heading-03 text-linear">Venha até nós</h2>
                        </div>
                    </div>
                </div>
            </section>
            <div className='container' style={{ padding: '3em', width:'100%' }}>
                <Map defaultCenter={[-8.991523, 13.399624]} defaultZoom={20} >
                    <Marker width={50} anchor={[-8.991523, 13.399624]} />
                </Map>
            </div>

        </>
    )
}
export default MapsLocation
