import React from 'react'
import { Footer } from '../components/Footer'
import { Menu } from '../components/Menu'
import { ListProjects } from '../Home/ListProjects'
import { Done } from './Done'
import { HowWeDow } from './HowWeDow'
import { Presentation } from './Presentation'


export const Projects = () => {
    return (
        <>
            <Menu />
            <Presentation/>
            <Done/>
            <ListProjects/>  
            <HowWeDow/>
            <Footer />
        </>
    )
}
