import React from 'react'

export const AboutUsHero = () => {
    return (
        <div>


            <section className="hero-01">
                <div className="custom-container">
                    <div className="hero-content-01">

                        <div className="title-wraps-01">
                            <img
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="300"
                                data-aos-duration="1000"
                                src="assets/images/logo/logo_orange.svg" loading="lazy" alt="" style={{ maxHeight: 260, margin: '0.1em' }} />
                            <h2
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="600"
                                data-aos-duration="1000"
                                data-w-id="a34abe5e-5450-365a-8b44-1d5ef3acbb5b"
                                className="hero-titles-01 heading-02 text-linear">Transforme sua visão em realidade</h2>

                            <div data-w-id="354793f9-f101-5fb9-f2ff-af41cff2bfe8"
                                className="hero-texts-01 body-01---r approx-silver-text"

                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="700"
                                data-aos-duration="1000"
                            >Com uma paixão pela criatividade e um compromisso com a excelência, capacitamos as marcas para prosperarem no cenário digital em constante evolução..</div>
                        </div>
                        <div className="btn-wrap-01">
                            <a data-w-id="aa9d0d8d-4fe5-1887-ef25-dd879f4d45c0"

                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="900"
                                data-aos-duration="1000"
                                href="/contacts" className="link-block-c w-inline-block">
                                <div className="btn-wrap">
                                    <div className="button-01 aboutbtn">
                                        <div className="btn-text white-text">Fale connosco</div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div data-w-id="872b311b-bb51-772f-7eae-556bf359d825"
                            className="video-story">
                            <img

                                data-aos="flip-left"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="700"
                                data-aos-duration="2000"
                                src="/assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937.webp" loading="lazy" sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 92vw, (max-width: 1279px) 93vw, 1260px" srcSet="/assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937-p-500.webp 500w, assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937-p-800.webp 800w, assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937-p-1080.webp 1080w, assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937-p-1600.webp 1600w, assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937-p-2000.webp 2000w, assets/images/65c235906c10ac1eb5fe5a7e_group_1171274937.webp 2320w"
                                alt="" className="story-thumb" />
                            <a href="#" className="story-lightbox w-inline-block w-lightbox">
                                <img
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-delay="900"
                                    data-aos-duration="1000"
                                    src="/assets/images/65c235906c10ac1eb5fe5a7f_Group%201000015223.svg" loading="lazy" alt="" className="playpausebutton" />

                                <script
                                    id="app-ld-json"
                                    type="application/json" className="w-json"
                                    dangerouslySetInnerHTML={{
                                        __html: JSON.stringify({
                                            "items": [{
                                                "url": "https://www.youtube.com/watch?v=rN3YnSg0WjM",
                                                "originalUrl": "https://www.youtube.com/watch?v=rN3YnSg0WjM",
                                                "width": 940,
                                                "height": 528,
                                               "thumbnailUrl": "https://i.ytimg.com/vi/yVUYpGm6bVE/hqdefault.jpg",
                                                "html": '<iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/rN3YnSg0WjM?si=iIeDzm6GGBIT9Uxo&amp;controls=0&amp;start=20" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
                                                "type": "video"
                                            }],
                                            "group": ""
                                        }, null, "\t"),
                                    }}
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
