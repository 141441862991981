import React from "react";
import './Header.scss';

export const Header = () => {
  return (
    <div id="Header" style={{ backgroundImage: "radial-gradient(#222222, #111111)" }}>
      <section className="hero-02">
        <div className="custom-container">
          <div className="hero-content-02">
            <div className="hero-title-wrap-02">
              <h2
                data-aos="fade-down"
                data-aos-duration="500"
                data-w-id="0dd1dd4d-8653-65b4-25a2-40904f85a6d8"
                className="hero-title-02 heading-01 text-linear-bonbon "
              >
                <img
                  src="assets/images/logo/ico_white.svg"
                  loading="lazy"
                  alt=""
                  style={{ height: 60, margin: "0.1em" }}
                />
                Hiperbite
              </h2>
              <div className="glow-box box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="200"
              >
                <h4
                  data-aos="fade-down"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                  data-w-id="0dd1dd4d-8653-65b4-25a2-40904f85a6d8"
                  className="hero-title-02 heading-01 text-linear"
                >
                  Transformando Ideias em Soluções Inovadoras
                </h4>
              </div>
              <div
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="600"
                data-w-id="ffd9c502-1c0d-d6b3-4df6-032eb5cecb63"
                className="hero-text-02 body-01---r approx-silver-text"
              >
                Empresa Angolana especializada em desenvolvimento de software
                personalizado e integração de sistemas multiplataformas, focada
                em soluções inovadoras para o sucesso dos clientes.
              </div>
              <div
                data-aos="fade-down"
                data-aos-duration="1000"
                data-aos-delay="800"
                className="hero-btn-wrap-02"
              >
                <a
                  data-w-id="2d27fc0b-a35e-d58c-b599-4af513891407"
                  href="/contact-us"
                  className="link-block-c w-inline-block button"
                >
                  <div className="btn-wrap " style={{ opacity: 1 }}>
                    <div className="button-01 hero03">
                      <div className="btn-text white-text">
                        Saiba mais sobre nós
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
