import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Home } from './Pages/Home';
import { Routes, Route } from "react-router-dom";
import { NotFound } from './Pages/NotFound';
import { AboutUs } from './Pages/AboutUs';

import { BrowserRouter } from 'react-router-dom';
import { Contacts } from './Pages/Contacs';
import { Projects } from './Pages/Projects';

import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {

  useEffect(() => {
    AOS.init();

  }, [])
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
    ;
}



const Routers = () => {
  return <>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about-us" element={<AboutUs />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
}
export default App;
