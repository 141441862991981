import React from "react";

export const Footer = () => {
  return (
    <>
      <section className="footer-section">
        <div className="custom-container">
          <div className="footer-content dark-shade-bg">
            <div className="footer-top">
              <div className="cta-title-wrap">
                <h2
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration="500"
                  data-w-id="8abe1496-eb78-a713-b60f-75b762d700a4"
                  className="cta-title heading-03 white-text"
                >
                  Entre em contacto connosco agora para começar!
                </h2>
                <div
                  data-w-id="8abe1496-eb78-a713-b60f-75b762d700a6"
                  className="cta-text body-01---r white-text"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                  }}
                >
                  Quer pretenda Digitalizar a sua Empresa ou criar uma solução
                  digital integrada, a nossa equipa da Hiperbite está aqui para
                  ajudar.
                </div>
                <div className="cta-btn-wrap">
                  <a
                    data-w-id="8abe1496-eb78-a713-b60f-75b762d700a9"
                    href="/contacts"
                    className="link-block-c w-inline-block"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                      opacity: 1,
                    }}
                  >
                    <div className="btn-wrap" style={{ opacity: 1 }}>
                      <div className="button-01">
                        <div className="btn-text white-text">Fale connosco</div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-bottom-wrap">
              <div className="footer-bottom">
                <div
                  id="w-node-_8abe1496-eb78-a713-b60f-75b762d700b0-62d7009f"
                  data-w-id="8abe1496-eb78-a713-b60f-75b762d700b0"
                  className="footer-col-b left"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                  }}
                >
                  <div className="fotoer-text-01 body-02---m white-text">
                    Subscreva e mantenha-te em contacto!
                  </div>
                  <div className="newsletter">
                    <div className="newsletter-form---bs w-form">
                      <form
                        id="email-form"
                        name="email-form"
                        data-name="Email Form"
                        method="get"
                        data-wf-page-id="65c235906c10ac1eb5fe5a11"
                        data-wf-element-id="8abe1496-eb78-a713-b60f-75b762d700b5"
                        aria-label="Email Form"
                      >
                        <div className="newsletter-from-group">
                          <div className="new-form-div">
                            <input
                              className="newsletter-input w-input"
                              maxLength={256}
                              name="name-2"
                              data-name="Name 2"
                              placeholder="O Seu email"
                              type="text"
                              id="name-2"
                            />
                            <img
                              src="assets/images/65c235906c10ac1eb5fe5a66_Group%20427319145.svg"
                              loading="lazy"
                              alt=""
                              className="envelop-icon"
                            />
                          </div>
                          <input
                            type="submit"
                            data-wait="Enviando, por favor aguarde..."
                            className="newsletter-submit w-button"
                            value="Subscrever"
                          />
                        </div>
                      </form>
                      <div
                        className="w-form-done"
                        tabIndex={-1}
                        role="region"
                        aria-label="Email Form success"
                      >
                        <div>Obrigado! A sua subscrição foi recebida com sucesso!</div>
                      </div>
                      <div
                        className="w-form-fail"
                        tabIndex={-1}
                        role="region"
                        aria-label="Email Form failure"
                      >
                        <div>
                          Oops! Alguma coisa não correu bem, por favor tente mais tarde.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-socials">
                    <a
                      href="https://web.facebook.com/hipertite"
                      target="_blank"
                      className="w-inline-block"
                    >
                      <img
                        src="assets/images/65c235906c10ac1eb5fe5a67_Group%201171274935.svg"
                        loading="lazy"
                        data-w-id="8abe1496-eb78-a713-b60f-75b762d700c3"
                        alt=""
                        className="social-logo"
                        style={{ opacity: 1 }}
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/hiperbite/"
                      target="_blank"
                      className="w-inline-block"
                    >
                      <img
                        src="assets/images/65c235906c10ac1eb5fe5a6a_Group%20427319385.svg"
                        loading="lazy"
                        data-w-id="8abe1496-eb78-a713-b60f-75b762d700c5"
                        alt=""
                        className="social-logo"
                        style={{ opacity: 1 }}
                      />
                    </a>
                    <a
                      href="https://www.tiktok.com/@hiperbite.ao"
                      target="_blank"
                      className="w-inline-block"
                    >
                      <img
                        src="assets/images/tiktoklogo.png"
                        loading="lazy"
                        data-w-id="8abe1496-eb78-a713-b60f-75b762d700c7"
                        alt=""
                        className="social-logo"
                        style={{ opacity: 1 }}
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/hiperbite"
                      target="_blank"
                      className="w-inline-block"
                    >
                      <img
                        src="assets/images/65c235906c10ac1eb5fe5a68_Group%20427319387.svg"
                        loading="lazy"
                        data-w-id="8abe1496-eb78-a713-b60f-75b762d700c9"
                        alt=""
                        className="social-logo"
                        style={{ opacity: 1 }}
                      />
                    </a>
                  </div>
                </div>
                <div className="footer-nav">
                  <div
                    id="w-node-_8abe1496-eb78-a713-b60f-75b762d700cb-62d7009f"
                    data-w-id="8abe1496-eb78-a713-b60f-75b762d700cb"
                    className="footer-col-b"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                      opacity: 1,
                    }}
                  >
                    <div className="footer-col-head">
                      <h2 className="footer-col-head-text">Menu</h2>
                    </div>
                    <div className="footer-col-body">
                      <a
                        href="/"
                        aria-current="page"
                        className="link-block-c w-inline-block w--current"
                      >
                        <div className="nav-text-f" style={{ opacity: 1 }}>
                          Início
                        </div>
                      </a>
                      <a
                        href="/about-us"
                        className="link-block-c w-inline-block"
                      >
                        <div className="nav-text-f" style={{ opacity: 1 }}>
                          Sobre Nós
                        </div>
                      </a>
                      <a
                        href="/projects"
                        className="link-block-c w-inline-block"
                      >
                        <div className="nav-text-f" style={{ opacity: 1 }}>
                          Projectos
                        </div>
                      </a>
                      <a
                        href="/contacts"
                        className="link-block-c w-inline-block"
                      >
                        <div className="nav-text-f" style={{ opacity: 1 }}>
                          Contactos
                        </div>
                      </a>
                    </div>
                  </div>

                  <div
                    id="w-node-_8abe1496-eb78-a713-b60f-75b762d700ed-62d7009f"
                    data-w-id="8abe1496-eb78-a713-b60f-75b762d700ed"
                    className="footer-col-b last"
                    style={{
                      transform:
                        "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d",
                      opacity: 1,
                    }}
                  >
                    <div className="footer-col-head">
                      <h2 className="footer-col-head-text body-medium-02 rich-black-text">
                        Contactos
                      </h2>
                    </div>
                    <div className="footer-col-body">
                      <div className="nav-text-ff">geral@hiperbite.ao</div>
                      <div className="nav-text-ff">+244 935 140 392</div>
                      <div className="nav-text-ff">
                        Vila PAcifica I12-1
                        <br /> APT 1101,
                        <br />
                        Luanda - Angola
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright-wrap">
                <p
                  data-w-id="8abe1496-eb78-a713-b60f-75b762d700fc"
                  className="copyright-text body-02---r white-text"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                    opacity: 1,
                  }}
                >
                  © Desenvolvido pela{" "}
                  <a
                    href="https://hiperbite.ao"
                    data-w-id="8abe1496-eb78-a713-b60f-75b762d700fe"
                    target="_blank"
                    className="basecom-text"
                    style={{ opacity: 1 }}
                  >
                    Hiperbite.ao{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
