import React from 'react'

export const Header = () => {
    return (
        <section className="contact-section">
            <div className="custom-container">
                <div className="project-content">
                    <div className="title-wrap-04">
                        <h2

                            data-aos="fade-up"
                            data-aos-anchor-placement="top-bottom"
                            data-aos-delay="300"
                            data-aos-duration="100000"

                            data-w-id="f33a5316-9c8f-5845-be6c-fbc4f6e71e9c"
                            className="title-04 heading-02 text-linear">Fale connosco </h2>
                    </div>
                    <div className="div-block-5 dark-shade-bg"
                        data-aos="fade-up"
                        data-aos-delay="500"
                        data-aos-anchor-placement="top-bottom"
                        data-aos-duration="8000"
                    >
                        <div data-w-id="ce6939ec-e7d7-8df5-27cf-f2132b2fa0ea"
                            className="div-block-6 slate-gold-bg">
                            <h3 className="heading-5 sub-heading-02">Informações Contactos</h3>
                            <div className="text-block-3 body-02---m">Tem dúvidas, perguntas ou está pronto para iniciar um projeto? Nós estamos aqui para ajudar..</div>
                            <div className="div-block-7">
                                <div className="div-block-4 _01"><img src="assets/images/65c235906c10ac1eb5fe5ada_Group%20427319412.svg" loading="lazy" alt="" className="my-image _01" />
                                    <div>
                                        <div className="my-text-block _01">Telefone/WhatsApp</div>
                                        <div className="text-block-2 _01">+244 935 140 392</div>
                                    </div>
                                </div>
                                <div className="div-block-4 _01"><img src="assets/images/65c235906c10ac1eb5fe5ad8_Group%20427319416.svg" loading="lazy" alt="" className="my-image _01" />
                                    <div>
                                        <div className="my-text-block _01">E-mail</div>
                                        <div className="text-block-2 _01">geral@hiperbite.ao</div>
                                    </div>
                                </div>
                                <div className="div-block-4 _01"><img src="assets/images/65c235906c10ac1eb5fe5ad9_Group%20427319409.svg" loading="lazy" alt="" className="my-image _01" />
                                    <div>
                                        <div className="my-text-block _01">Visite o nosso escritório</div>
                                        <div className="text-block-2 _01">Vila PAcifica I12-1, APT 1101,<br />Luanda - Angola</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="div-block-8">
                            <div className="w-form">
                                <form method="get" name="email-form-2" data-name="Email Form 2"
                                    data-w-id="db2248e3-24b8-5ded-183e-c77a7f4603f5" id="email-form-2" data-wf-page-id="65c235906c10ac1eb5fe5a20" data-wf-element-id="db2248e3-24b8-5ded-183e-c77a7f4603f5">
                                    <input className="contact-filed body-02---m w-input" maxLength={256} name="name-3" data-name="Name 3" placeholder="Seu Nome" type="text" id="name-3" />
                                    <input className="contact-filed w-input" maxLength={256} name="email" data-name="Email" placeholder="Seu e-mail" type="email" id="email" required />
                                    <textarea className="contact-filed w-input" maxLength={256} name="email-2" data-name="Email 2" placeholder="Sua mensagem" required ></textarea>
                                    <div className="btn-wrap">
                                        <div className="button-01">
                                            <div className="btn-text white-text">Enviar mensagem</div>
                                        </div>
                                    </div>
                                </form>
                                <div className="w-form-done">
                                    <div>Thank you! Your submission has been received!</div>
                                </div>
                                <div className="w-form-fail">
                                    <div>Oops! Something went wrong while submitting the form.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
