import React from 'react'
import { Particles } from '../Home/Particles'

export const Presentation = () => {
    return (
        <>

            <section className="projects-01">
                <div className="custom-container project01">

                    <div className="title-wraps-01">

                        <h2

                            data-aos="fade-up"
                            data-aos-delay="200"
                            data-aos-duration="1000"
                            data-aos-anchor-placement="top-bottom"
                            data-w-id="a34abe5e-5450-365a-8b44-1d5ef3acbb5b"
                            className="hero-titles-01 heading-02 text-linear">
                                
                                <br/>
                                Encontre Nossos Melhores Projetos e Obras</h2>

                        <div

                            data-aos="fade-up"
                            data-aos-delay="600"
                            data-aos-duration="1000"
                            
                            data-w-id="354793f9-f101-5fb9-f2ff-af41cff2bfe8"
                            className="hero-texts-01 body-01---r approx-silver-text">Com uma paixão pela criatividade e um compromisso com a excelência, capacitamos as marcas para prosperarem no cenário digital em constante evolução..</div>
                    </div>
                    <div className="project-content">
                        <div data-delay="4000" data-animation="slide" className="project-sliders w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                            <div className="project-mask w-slider-mask">

                                <div className="project-slide w-slide">

                                    <div className="w-dyn-list">

                                        <div role="list" className="w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <a href="https://nova.ao" target={'_blank'} className="link-block-c w-inline-block" style={{width:'100%'}}>
                                                    <div data-w-id="9381a029-b5bc-6f5e-7bb0-f96869dea82f"
                                                        className="project-item">
                                                        <div className="project-thumb">
                                                            <img loading="lazy" src="/assets/images/galery/selection.jpg" alt="" sizes="(max-width: 767px) 83vw, (max-width: 991px) 89vw, (max-width: 1279px) 92vw, 1040px"
                                                                srcSet="/assets/images/galery/selection.jpg"
                                                                className="project-thum" />
                                                            <div className="project-icons"><img loading="lazy" src="https://assets-global.website-files.com/65c235906c10ac1eb5fe5a18/662b3adbea4123556e9895ca_Group%201000017041%20(1).svg" alt="" className="project-icon" /><img loading="lazy" src="https://assets-global.website-files.com/65c235906c10ac1eb5fe5a18/662b3acd8b82b058df1ab589_Group%201171274936%20(1).svg"
                                                                alt="" className="project-icon" /></div>
                                                        </div>
                                                        <div className="project-body">
                                                            <div className="project-title-w">
                                                                <h3 className="project-body-title heading-04 text-linear">
                                                                    Sistema de candidatura
                                                                    <p>
                                                                        Intituto Superior Politécnico do Bengo
                                                                    </p>

                                                                </h3>

                                                                <img src="assets/images/65c235906c10ac1eb5fe5ab2_Rectangle%2025.svg" loading="lazy" alt="" className="virtical-divider" />

                                                            </div>

                                                            <div className="project-bodies-text">
                                                                <div className="project-texts">
                                                                    <div className="project-text02 body-03 approx-silver-text">Escopo.</div>
                                                                    <div className="project-text01 sub-heading-04 white-text">Portal instituicional</div>
                                                                </div>
                                                                <div className="project-texts">
                                                                    <div className="project-text02 body-03 approx-silver-text">Tipo de aplicação.</div>
                                                                    <div className="project-text01 sub-heading-04 white-text">Cloud Application</div>
                                                                    <div className="project-text02 body-03 approx-silver-text">Cliente.</div>
                                                                    <div className="project-text01 sub-heading-04 white-text">ISPBengo</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-slide w-slide">
                                    <div className="w-dyn-list">
                                        <div role="list" className="w-dyn-items">
                                            <div role="listitem" className="w-dyn-item">
                                                <a href="/projects/superflow" className="link-block-c w-inline-block">
                                                    <div className="project-item">
                                                        <div className="project-thumb"><img loading="lazy" src="/assets/images/galery/stock.jpg" alt="" sizes="(max-width: 767px) 83vw, (max-width: 991px) 89vw, (max-width: 1279px) 92vw, 1040px"
                                                            srcSet="/assets/images/galery/stock.jpg"
                                                            className="project-thum" />
                                                            <div className="project-icons"><img loading="lazy" src="https://assets-global.website-files.com/65c235906c10ac1eb5fe5a18/662b3adbea4123556e9895ca_Group%201000017041%20(1).svg" alt="" className="project-icon" /><img loading="lazy" src="https://assets-global.website-files.com/65c235906c10ac1eb5fe5a18/662b3acd8b82b058df1ab589_Group%201171274936%20(1).svg"
                                                                alt="" className="project-icon" /></div>
                                                        </div>
                                                        <div className="project-body">
                                                            <div className="project-title-w">
                                                                <h3 className="project-body-title heading-04 text-linear">
                                                                    Sistema de Gestão de ativos</h3><img src="assets/images/65c235906c10ac1eb5fe5ab2_Rectangle%2025.svg" loading="lazy" alt="" className="virtical-divider" />
                                                            </div>
                                                            <div className="project-bodies-text">
                                                                <div className="project-texts">
                                                                    <div className="project-text01 sub-heading-04 white-text">Escopo</div>
                                                                    <div className="project-text02 body-03 approx-silver-text">Sistema ERP.</div>
                                                                </div>
                                                                <div className="project-texts">
                                                                    <div className="project-text02 body-03 approx-silver-text">Tipo de aplicação.</div>
                                                                    <div className="project-text01 sub-heading-04 white-text">Cloud Application</div>
                                                                    <div className="project-text02 body-03 approx-silver-text">Cliente.</div>
                                                                    <div className="project-text01 sub-heading-04 white-text">SANEP</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="arrow left w-slider-arrow-left"><img src="assets/images/65c235906c10ac1eb5fe5aae_Group%2025.svg" loading="lazy" alt="" className="arrow-icon" /></div>
                            <div className="arrow right w-slider-arrow-right"><img src="assets/images/65c235906c10ac1eb5fe5aa7_Group%2024.svg" loading="lazy" alt="" className="arrow-icon" /></div>
                            <div className="slide-nav w-slider-nav w-round w-num"></div>
                        </div>
                    </div>
                </div>
            </section>
                <Particles/>
        </>
    )
}
