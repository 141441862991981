import React from 'react'

export const Services = () => {
    return (
        <>

            <section className="servoces">
                <div className="custom-container service">
                    <div className="services-content">
                        <div className="title-wrap-02">
                            <h2 data-w-id="84a6b87a-acfc-6713-d516-63882943cfdb"
                                data-aos="fade-up"
                                data-aos-anchor-placement="top-bottom"
                                data-aos-delay="100"
                                data-aos-duration="1000"
                                className="title-02 heading-03 text-linear">Nossos Princípios Fundamentais</h2>
                        </div>
                        <div className="service-list">
                            <div data-w-id="db0005fe-65aa-b10f-3ff2-b186036c8cb4"
                                className="service-left">
                                <div
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-delay="300"
                                    data-aos-duration="1000"
                                    className="service-item"><img src="/assets/images/65c235906c10ac1eb5fe5a85_Vector%2013.svg" loading="lazy" alt="" className="item-divider" />
                                    <h2 className="service-title sub-heading-02 approx-silver-text"><strong>Missão</strong></h2>
                                    <div className="service-text body-02---r approx-silver-text">Desenvolver soluções de software sob medida que impulsionem o sucesso de nossos clientes.</div>
                                </div>
                                <div

                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-delay="500"
                                    data-aos-duration="1000"
                                    className="service-item"><img src="/assets/images/65c235906c10ac1eb5fe5a85_Vector%2013.svg" loading="lazy" alt="" className="item-divider" />
                                    <h2 className="service-title sub-heading-02 approx-silver-text"><strong>Visão </strong></h2>
                                    <div className="service-text body-02---r approx-silver-text">Ser líder em inovação tecnológica e referência em qualidade de serviços.                                    </div>
                                </div>
                            </div>
                            <div data-w-id="81d693bf-c557-1c39-4823-184cb136951a"
                                className="service-middle"><img src="/assets/images/65c235906c10ac1eb5fe5a7d_group_1000015324.webp" loading="lazy" sizes="(max-width: 479px) 96vw, (max-width: 767px) 90vw, (max-width: 991px) 72vw, (max-width: 1279px) 37vw, (max-width: 1439px) 39vw, 560px" srcSet="/assets/images/65c235906c10ac1eb5fe5a7d_group_1000015324-p-500.webp 500w, assets/images/65c235906c10ac1eb5fe5a7d_group_1000015324-p-800.webp 800w, assets/images/65c235906c10ac1eb5fe5a7d_group_1000015324-p-1080.webp 1080w, assets/images/65c235906c10ac1eb5fe5a7d_group_1000015324.webp 1120w"
                                    alt="" className="item-middle-img" /></div>
                            <div data-w-id="c9f1d132-59b4-8dab-980a-72e419238f2a"
                                className="service-right">
                                <div
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-delay="600"
                                    data-aos-duration="1000"
                                    className="service-item"><img src="/assets/images/65c235906c10ac1eb5fe5a85_Vector%2013.svg" loading="lazy" alt="" className="item-divider" />
                                    <h2 className="service-title sub-heading-02 approx-silver-text">Valores</h2>
                                    <div className="service-text body-02---r approx-silver-text">Excelência * Compromisso * Inovação * Trabalho em Equipe * Integridade * Aprendizado. </div>
                                </div>
                                <div
                                    data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom"
                                    data-aos-delay="600"
                                    data-aos-duration="1000"
                                    className="service-item"><img src="/assets/images/65c235906c10ac1eb5fe5a85_Vector%2013.svg" loading="lazy" alt="" className="item-divider" />
                                    <h2 className="service-title sub-heading-02 approx-silver-text"><strong>Responsabilidade Social</strong></h2>
                                    <div className="service-text body-02---r approx-silver-text">Sustentabilidade , Comunidade e Inclusão
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
